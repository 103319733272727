import { createStore } from "redux";

const initialState = {
  surfcams: [],
  lan: "",
  attempts: {
    value: 0,
    date: new Date()
  },
  socket: {
    action: null
  }
};


function saveToLocalStorage(state) {
  try {
    let newData = {
      surfcams: [],
      attempts: state.attempts
    };
    if (state) {
      if (state.spots && state.spots.tabs) {
        newData = {
          spots: state.spots,
          attempts: state.attempts
        };
      }
      if (state.lan !== "" && state.lan !== null && state.lan !== undefined) {
        newData = {
          ...newData,
          lan: state.lan,
        };
      }
    }
    const serializedSate = JSON.stringify(newData);
    // eslint-disable-next-line no-undef
    localStorage.setItem("state", serializedSate);
  } catch (e) {}
}

function loadFromLocalStorage() {
  try {
    // eslint-disable-next-line no-undef
    const serializedSate = localStorage.getItem("state");
    if (serializedSate === null) return undefined;
    const data = JSON.parse(serializedSate);
    if (data && data.spots && data.spots.tabs) {
      return {
        lan: data.lan,
        spots: data.spots,
        attempts: data.attempts
      };
    } else {
      return {
        lan: data.lan,
        attempts: data.attempts
      };
    }
  } catch (e) {
    return undefined;
  }
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case "LOGIN":
      return {
        ...state,
        user: action.data,
        status: "logged",
        cookie: true,
      };
    case "CURRENT_TIME":
      return{
        ...state,
        currentTime: action.data
      };
    case "SELECT_PLAN":
      return {
        ...state,
        selectedPlan: action.data,
      };
    case "ADD_COUPON":
      return {
        ...state,
        couponId: action.data,
      };

    case "CHECK_COOKIE":
      return {
        ...state,
        cookie: true,
      };
    case "OPEN_LOGIN":
      return {
        ...state,
        login: {
          path: action.data.path,
          search: action.data.search,
        },
      };
    case "SURFCAMS":
      return {
        ...state,
        surfcams: action.data,
      };
    case "SPOT":
      return {
        ...state,
        spot: action.data,
      };
    case "LANGUAGE":
      return {
        ...state,
        lan: action.data,
      };
    case "COUNTRY":
      return {
        ...state,
        country: action.data,
      };
      case "LOGIN_ATTEMPT":
        return {
          ...state,
          attempts: action.data,
        };  
    case "MULTISPOTS":
      return {
        ...state,
        user: {
          ...state.user,
          multispot: action.data,
        },
      };
    case "USER_NAME":
      return {
        ...state,
        user: {
          ...state.user,
          name: action.data,
        },
      };
    case "AVATAR":
      return {
        ...state,
        user: {
          ...state.user,
          profileImageUrl: action.data,
        },
      };
      case "FAVORITES":
        return {
          ...state,
          user: {
            ...state.user,
            favorites: action.data,
          },
        };
    case "CLEAN_MULTISPOTS":
      return {
        ...state,
        spots: null,
      };

    case "NPS_ACTIVE":
      return {
        ...state,
        npsActive: action.data.npsActive,
        npsType: action.data.npsType,
        npsSpot: action.data.npsSpot,
        npsTime: action.data.npsTime,
      };
    case "CHECKOUT":
      return {
        ...state,
        checkoutSuccess: true,
      };
    case "SHOW_PREMIUM_TO_FREE":
      return {
        ...state,
        showModalToFree: action.data,
      };
    case "ACCESS_GUEST":
      return {
        ...state,
        user: {
          id: action.data,
        },
      };
    case "SUSCRIPTION":
      return {
        ...state,
        suscription: action.data,
      };
    case "UPDATE":
      return {
        ...state,
        [action.data.field]: action.data.value,
      };
    case "HANDLE_STREAMING_TIME":
      return {
        ...state,
        user: {
          ...state.user,
          availableStreamingTime: action.data.freeTime,
          paidStreamingTime: action.data.paidTime
        },
      };
    case "SELECTED_PLAN":
      return {
        ...state,
        selectedPlan: action.data,
      };
    case "SPONSORS":
      return {
        ...state,
        sponsors: action.data,
      };
    case "SOCIAL_INVITE":
      return {
        ...state,
        socialInvite: action.data,
      };
      case "ASK_DOCUMENT":
        return {
          ...state,
          askDocument: action.data,
        };
      case "HANDLE_SOCKET":
        return {
          ...state,
          socket: action.data,
        };
      case "SET_FINGERPRINT":
        return {
          ...state,
          fingerprint: action.data,
        };
      case "NOTIFICATIONS":
        return {
          ...state,
          unreadNotificationsCounter: action.data,
        };
      case "PAYMENT_STATUS":
        return{
          ...state,
          paymentStatus: action.data,
        };
      case "APPLE_USER":
        return {
          ...state,
          isAppleAccount: action.data,
        };
      case "SEARCHBAR_LIST": 
        return {
          ...state,
          searchbarList: action.data,
        }
    case "LOGOUT":
      return { ...initialState, socket: state.socket, sponsors: state.sponsors, spots: state.spots };
    case "DEVICE_INFO":
        const { isMobile, isPwa, isAppleDevice} = action.data;
        return {...initialState, isMobile: isMobile, isPwa: isPwa, isAppleDevice: isAppleDevice };
    default:
      return state;
  }
};

const persistedState = loadFromLocalStorage();

const store = createStore(reducer, persistedState, window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__());

store.subscribe(() => saveToLocalStorage(store.getState()));

export default store;
