import { Alert } from "antd";
import React, { useEffect, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import { AiOutlineArrowLeft } from "react-icons/ai";
import { connect } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import Animate from "react-smooth/lib/Animate";
import Lottie from "react-lottie";
import animationData from "../assets/animations/gift.json";
import styled from "styled-components";
import BillingComponent from "../components/billingComponent";
import LoaderComponent from "../components/LoaderComponent";
import WarningContainer from "../components/warningContainer";
import gtmService from "../modules/gtmService";
import payment from "../modules/payment";
import constant from "../utils/constant";
import utils from "../utils/utils";

const CheckoutView = (props) => {
  const { selectedPlan, user, status } = props;
  const { plan, coupon, userReferrer, otherPlans } = selectedPlan;
  const [isLoading, setIsLoading] = useState(true); //True
  const [currency, setCurrency] = useState(""); //True
  const [isHundredPercentDiscount, setIsHundredPercentDiscount] = useState(false); // True
  const [baseDiscount, setBaseDiscount] = useState(30); // True, a revisar
  const [annualListPrice, setAnnualListPrice] = useState(3.99 * 12); //True
  const [discountPercentage, setDiscountPercentage] = useState(30); //True
  const [valueToBeDiscounted, setValueToBeDiscounted] = useState(0); //True
  const [finalValue, setFinalValue] = useState(0); //True
  const [isMobile] = useState(utils.isUsingMobile());
  const { t } = useTranslation();
  let history = useHistory();
  const hundredPercentDiscount = 100;
  console.log(selectedPlan);

  useEffect(() => {
    if(coupon?.amount === hundredPercentDiscount){
      setIsHundredPercentDiscount(true);
      setBaseDiscount(0);
    }

    handlePrice();
  }, [selectedPlan]);

  useEffect(() => {
    handleCheckout();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    if (user.nextPlanId === constant.SUBSCRIPTION.FREE
      && (user.isPremiumCourtesy == null || user.isPremiumCourtesy == false)) {
      payment.visitedCheckout();
    }

    const monthlyAmount = Number((otherPlans[constant.SUBSCRIPTION.MONTHLY]??plan).amount);
    setAnnualListPrice(monthlyAmount * 12);
  }, []);

  const handleCheckout = async () => { // a corregir
    if (selectedPlan) {
      gtmService.sendEvent("begin_checkout");
      setCurrency(plan.currency);
    } else {
      history.push({
        pathname: "/premium",
        search: window.location.search,
        state: { status: status, user: user },
      });
    }
    setIsLoading(false);
  };

  const handlePrice = () => {
    let handledDiscountPercentage = 0;      
    let handledValueToBeDiscounted = plan.saving_money;      
    let handledFinalValue = Number(plan.amount);    
    
    if (plan.id === constant["SUBSCRIPTION"]["ANNUALLY"])
      handledDiscountPercentage = baseDiscount;

    if(coupon){
      if(isHundredPercentDiscount){
        handledDiscountPercentage = 100;
        handledValueToBeDiscounted = otherPlans['2'].amount * 12;
        handledFinalValue = 0;
      }
      const annualizedMonthlyPrice = otherPlans['2'].amount * 12;
      const couponDiscountOfAnual = plan.amount * (coupon.amount / 100);
      const percentageFromMonthlyAnualized = (couponDiscountOfAnual / (annualizedMonthlyPrice)) * 100;
      const priceWithDiscount = (plan.amount - couponDiscountOfAnual); // This is the price what i want to show in the card. (rounded up)

      handledDiscountPercentage = Math.ceil(percentageFromMonthlyAnualized + baseDiscount); // This is the discount what i want to show i the card. (rounded up)
      handledValueToBeDiscounted = annualizedMonthlyPrice - priceWithDiscount;
      handledFinalValue = priceWithDiscount;
      //Math.ceil corresponds to math ceiling function.

    }
    setDiscountPercentage(handledDiscountPercentage);
    setValueToBeDiscounted(handledValueToBeDiscounted);
    setFinalValue(handledFinalValue);
  } 

  const goBack = (url) => {
    history.push({
      pathname: url,
      search: window.location.search,
      state: { status: status, user: user },
    });
  };

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  return (
    <CheckoutContainer>
      {isLoading || !selectedPlan ? (
        <LoaderComponent />
      ) : (
        <Animate to="1" from="0" duration={500} attributeName="opacity">
          <Header>
            <Link to={"/"}>
              <img src={require("../assets/logo/logo.svg")} alt={""} />
            </Link>
          </Header>
          <div className="container-xl">
            <div className="row">

              <SuscriptionSummary className="col-lg-6">

                <button style={{ backgroundColor: "transparent", border: "none" }}
                  onClick={() => history.goBack()}>
                  <AiOutlineArrowLeft /> {t("checkout_view_back")}
                </button>

                {/* MAIN TITLE */}
                <h2>{plan.id === constant.SUBSCRIPTION.REFILL ? t("checkout_view_title_refill") : t("checkout_view_title")}</h2>

                {!user.canUseFreeTrial || plan.id === constant.SUBSCRIPTION.ESPECIAL_DAY || isHundredPercentDiscount ? (
                  <React.Fragment>

                    {/* FINAL PRICE PER MONTH */}
                    <h3>{currency}&nbsp;{utils.getFormattedAmount((plan.id == '3' ? finalValue / 12 : finalValue))}&nbsp;<TypePrice>/&nbsp;{t("premium_landing_view_per_month")}</TypePrice></h3>

                    {/* LIST PRICE */}
                    <CardSummary>
                      <span>{currency}&nbsp;{utils.getFormattedAmount(annualListPrice / 12)}&nbsp;/&nbsp;{t("premium_landing_view_per_month")}</span>
                      <h4>{t("_LINEUP_PREMIUM")}</h4>
                      <h6>
                        {plan.id === constant.SUBSCRIPTION.ESPECIAL_DAY ? (
                          t("premium_landing_view_recurring_billing_semestral")
                        ) : plan.id === constant.SUBSCRIPTION.ANNUALLY ? (
                          <Trans i18nKey="premium_landing_view_recurring_billing_anual" />
                        ) : (
                          t("premium_landing_view_recurring_billing_monthly")
                        )}
                      </h6>
                    </CardSummary>

                    <TotalSummary>

                      {/* SUBTOTAL */}
                      <span>
                        <span>{plan.id === constant.SUBSCRIPTION.ESPECIAL_DAY ? t("6_MONTH") : plan.id === constant.SUBSCRIPTION.ANNUALLY ? <Trans i18nKey={"12_MONTH"} values={{ currency: plan.currency, amount: utils.getFormattedAmount(otherPlans[constant.SUBSCRIPTION.MONTHLY].amount) }} /> : ""}</span>{" "}
                        {plan.id === constant.SUBSCRIPTION.ANNUALLY ? (currency + " " + utils.getFormattedAmount(annualListPrice)) : (currency + " " + utils.getFormattedAmount(finalValue))}
                      </span>
                      <h5>{t("_SUBTOTAL")}</h5>

                      {/* COUPON DISCOUNT */}
                      {coupon !== null && (
                        <div>
                          <span><span>({discountPercentage}% OFF)</span>&nbsp;-&nbsp;{currency}&nbsp;{utils.getFormattedAmount(valueToBeDiscounted)}</span>
                          <h5>{t("checkout_view_discount_coupon")}&nbsp;<strong>{selectedPlan?.coupon?.data?.name}</strong></h5>
                        </div>
                      )}

                      {/* ANNUAL DISCOUNT, NO COUPON */}
                      {!coupon && plan.id === constant.SUBSCRIPTION.ANNUALLY && (
                        <div>
                          {/* (30% OFF) is the normal annual discount */}
                          <span><span>({baseDiscount}% OFF)</span>&nbsp;-&nbsp;{currency}&nbsp;{utils.getFormattedAmount(valueToBeDiscounted)}</span>
                          <h5>{t("checkout_view_discount_annual")}&nbsp;<strong>{selectedPlan.coupon?.name}</strong></h5>
                        </div>
                      )}

                      {/* TOTAL PAYING TODAY */}
                      <TotalAmount>{currency}&nbsp;{utils.getFormattedAmount(finalValue)}</TotalAmount>
                      <h4>
                        {t("checkout_view_total_amount")}&nbsp;
                        {user.currentPlanId !== constant.SUBSCRIPTION.FREE && user.nextPlanId === constant.SUBSCRIPTION.FREE
                          ? t("checkout_view_total_amount_pay_from") + " " + utils.formatDayToShow(user.endCurrentPlan)
                          : t("_TODAY")}
                      </h4>

                    </TotalSummary>
                  </React.Fragment>
                ) : (
                  <FreeTrialCard>
                    {/* ONLY SHOWN IF FREE TRIAL */}
                    <h3>{t("checkout_view_free_trial")}</h3>
                    {plan.id === constant.SUBSCRIPTION.ANNUALLY ? (
                      <React.Fragment>
                        <h5>
                          <Trans i18nKey={"checkout_view_billing_anual"}
                            values={{ planTotal: utils.getFormattedAmount(finalValue), currency: currency, discountPercentage: discountPercentage }} />
                        </h5>
                      </React.Fragment>
                    ) : (
                      <h5>
                        <Trans i18nKey={"checkout_view_billing_monthly"} values={{ planTotal: utils.getFormattedAmount(finalValue), currency: currency }} />
                      </h5>
                    )}
                  </FreeTrialCard>
                )}

                {isHundredPercentDiscount && <WarningContainer type="info" isAB={false} message={t("hundred_percent_congratulation_message")} />}

                {!!userReferrer && (!user.public_id || user.public_id !== userReferrer.publicId) && (
                  <>
                    <Alert
                      message={
                        <>
                          <ViewUserReferralInformation>
                            {t("obtain")} {plan.id === constant.SUBSCRIPTION.ANNUALLY ? "2" : "1"}{" "}
                            {plan.id === constant.SUBSCRIPTION.ANNUALLY ? t("checkout_view_referral_additional_months") : t("checkout_view_referral_additional_month")}{" "}
                            {userReferrer.name} {t("checkout_view_referral_additional_months_referred_you")}
                          </ViewUserReferralInformation>
                        </>
                      }
                      description=""
                      type="info"
                    />
                  </>
                )}
              </SuscriptionSummary>

              <div className="col-lg-6" style={isHundredPercentDiscount ? { display: "flex", alignItems: "center", justifyContent: "center" } : {}}>
                <BillingInfo style={isHundredPercentDiscount ? { marginTop: 0 } : {}}>
                  {!isHundredPercentDiscount && (
                    <>
                      <h2>{t("checkout_view_billing_info")}</h2>
                      <h3>{t("checkout_view_billing_Dlocal")}</h3>{" "}
                    </>
                  )}
                  {isHundredPercentDiscount && <Lottie options={defaultOptions} height={isMobile ? 100 : 200} width={isMobile ? 100 : 200} />}
                  <BillingComponent
                    user={user}
                    selectedPlan={plan}
                    discount={coupon}
                    referrerId={userReferrer?.publicId}
                    goToUrl={plan.id === constant.SUBSCRIPTION.REFILL ? "/plans/minutes/checkout/success" : "/premium/checkout/success"}
                    isHundredPercentDiscount={isHundredPercentDiscount}
                  />
                  {!isHundredPercentDiscount && (
                    <>
                      <PoweredByDlocal>
                        <img src={require("../assets/icons/DLocal-logo.png")} alt={""} />
                      </PoweredByDlocal>
                    </>
                  )}
                </BillingInfo>
              </div>
            </div>
          </div>
          <WppButton href={t("wpp_invitation")} target="_blank">
            <img src={require("../assets/icons/wpp-icon.svg")} alt={""} />
          </WppButton>
          <Footer>
            <img src={require("../assets/logo/white-logo.svg")} alt={""} />
          </Footer>
        </Animate>
      )}
    </CheckoutContainer>
  );
};

const CheckoutContainer = styled.div`

  @media( min-width: 1000px ){
    position: relative;
    min-height: 100vh;

    .container-xl{
      padding-bottom: 150px;
    }
  }

`

const PoweredByDlocal = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 30px;

  img {
    width: 80px;
    height: auto;
    object-fit: contain;
    opacity: 0.8;
  }
`;

const FreeTrialCard = styled.div`
  p {
    margin-top: -5px;
    font-size: 0.9em;
    color: #747574;
  }
`;

const WppButton = styled.a`
  position: fixed;
  right: 100px;
  bottom: 175px;
  z-index: 99;

  img {
    width: 55px;
    bottom: 10px;
    right: 10px;
  }

  @media (max-width: 1000px) {
    bottom: 91px;
    right: 25px;

    img {
      width: 45px;
    }
  }
`;

const BillingInfo = styled.div`
  padding: 70px;
  padding-top: 20px;

  @media (max-width: 1000px) {
    padding: 10px;
    padding-top: 20px;
    margin-bottom: 40px;
  }

  h2 {
    margin-top: 20px;
    font-size: 1em;
    color: #000;
    font-weight: 500;
  }

  h3 {
    margin-top: 10px;
    font-size: 0.8rem;
    color: #000;
    font-weight: 500;
  }
`;

const TotalAmount = styled.span`
  font-size: 0.9em!important;
  font-weight: bold!important;
  margin-top: 10px;
`;

const ViewUserReferralInformation = styled.p`
  font-size: 1em;
  color: #000;
  text-align: center;

  @media (max-width: 1000px) {
    padding: 0px 30px;
    margin-top: 10px;
  }
`;

const TotalSummary = styled.div`
  h4 {
    font-size: 0.9em;
    color: #000;
    font-weight: 600;
    margin-top: 20px;
    margin-bottom: 20px;
  }

  h5 {
    font-size: 1em;
    color: #000;
    margin-top: 30px;
    border-bottom: 1px solid hsla(0, 0%, 10%, 0.1);
    padding-bottom: 20px;
    text-align: left!important;
  }

  span {
    float: right;
    font-size: 0.8em;
    font-weight: 600;

    span {
      margin-top: 2px;
      margin-right: 10px;
      float: left;
    }
  }
`;

const CardSummary = styled.div`
  border: 1px solid hsla(0, 0%, 10%, 0.1);
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
  padding: 16px;
  margin-top: 20px;

  span {
    font-size: 0.9em;
    float: right;
  }

  h4 {
    font-size: 1em;
    color: #000;
    font-weight: 500;
  }

  h5 {
    font-size: 0.8em;
    color: #b0b0b0;
    margin-top: 10px;
  }

  h6 {
    font-size: 0.8em;
    margin-top: 0px;
    color: #747574;
  }
`;

const SuscriptionSummary = styled.div`
  padding: 30px;
  a {
    color: #000;
    font-weight: 500;
    margin: 20px 0px;
  }

  h2 {
    margin-top: 40px;
    font-size: 1em;
    color: #000;
    font-weight: 400;

    @media (max-width: 1000px) {
      text-align: center;
    }
  }

  h3 {
    font-size: 1.7em;
    font-weight: 700;
    margin: 12px 0px;
    margin-bottom: 8px;

    @media (max-width: 1000px) {
      text-align: center;
    }
  }

  h5 {
    font-size: 0.9em;
    color: #000;
    font-weight: 400;

    @media (max-width: 1000px) {
      text-align: center;
    }
  }

  .prorated-amount {
    margin-top: 20px !important;
  }
`;

const TypePrice = styled.span`
  font-size: 0.4em;
  font-weight: 600;
  margin-left: 0px;
`;

const Header = styled.div`
  width: 100%;
  border-bottom: 2px solid #e5e5e58c;
  margin-bottom: 40px;

  @media (max-width: 1000px) {
    text-align: center;
    margin-bottom: 0px;
  }

  img {
    padding: 25px;
    width: 200px;
  }
`;

const Footer = styled.div`
  width: 100%;
  background-color: #2b5283;
  height: 150px;
  text-align: center;
  object-position: center;
  padding-top: 50px;
  margin-top: 140px !important;
  bottom: 0;

  @media (min-width: 1000px) {
    position absolute
  }

  img {
    width: 150px;
    text-align: center;
    object-position: center;
    display: flex;
    margin: auto;
  }
`;

const mapStateToProps = (state) => ({
  status: state.status,
  user: state.user,
  selectedPlan: state.selectedPlan,
  selectPlan: state.selectPlan,
});

export default connect(mapStateToProps)(CheckoutView);
